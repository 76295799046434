<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">Maintenance<br /></h1>
              <h3 class="subtitle has-text-light-grey is-size-5">
                Keep your web systems performing at their best.
              </h3>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature-content columns">
      <div class="column is-size-4">
        <h1>
          We offer <span>ongoing maintenance agreements</span> to keep our
          client's web systems running as intended. <br /><br />
          In addition to standard tasks such as
          <span>scheduled maintenance</span>, <span>tuning</span>,
          <span>security monitoring</span> and <span>patching</span>, we also
          deliver continuous improvement to the systems by delivering
          value-added services such as
          <span>content management, feature enhancements</span> and
          <span>design changes</span> to keep your systems engaging and
          relevant. <br /><br />
          Our clients can <span>rest assured</span> knowing their system is
          being maintained by the people who know it best.
        </h1>
      </div>
    </section>
  </div>
</template>

<style scoped>
@import "../../styles/feature-content.scss";
.hero-body {
  background: url("/img/service-banner/maintenance-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: darken;
}
#hero-home {
  position: relative;
}
#hero-home:after {
  content: "\A";
  background-image: url("/img/service-banner/maintenance-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>

<script>
export default {
  name: "Maintenance"
}
</script>
